<template>
  <div>
    <div class="header">
      <button @click="newContact()">Ajouter un contact</button>
    </div>

    <div class="search">
      <input type="text" v-model="search" placeholder="Rechercher un contact...">
    </div>

    <table>
      <thead>
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Email</th>
        <th>Téléphone</th>
        <th>Société</th>
        <th>Adresse</th>
        <th width="150px"></th>
        <th width="150px"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="contact in contacts">
        <td>{{ contact.name }}</td>
        <td>{{ contact.prenom }}</td>
        <td>{{ contact.email }}</td>
        <td>{{ contact.tel }}</td>
        <td>{{ contact.company }}</td>
        <td>{{ contact.adresse }} {{ contact.cp }} {{ contact.ville }}</td>
        <td>
          <button @click="edit(contact)">Modifier</button>
        </td>
        <td>
          <button @click="remove(contact)">Supprimer</button>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="modal" v-if="contact">
      <div>
        <div class="flex">
          <div class="row">
            <label>Nom</label>
            <input type="text" name="nom" v-model="contact.name" placeholder="Nom">
          </div>

          <div class="row">
            <label>Prénom</label>
            <input type="text" name="prenom" v-model="contact.prenom" placeholder="Prénom">
          </div>
        </div>

        <div class="flex">
          <div class="row">
            <label>Email</label>
            <input type="email" name="email" v-model="contact.email" placeholder="Email">
          </div>
          <div class="row">
            <label>Tél.</label>
            <input type="text" name="phone" v-model="contact.tel" placeholder="Téléphone">
          </div>
        </div>
        <div class="flex">
          <div class="row">
            <label>Société</label>
            <input type="text" name="societe" v-model="contact.company" placeholder="Société">
          </div>
        </div>

        <div class="flex">
          <div class="row">
            <label>Adresse</label>
            <input type="text" v-model="contact.adresse" placeholder="Adresse">
          </div>
          <div class="row">
            <label>Code postal</label>
            <input type="text" v-model="contact.cp" placeholder="Code postal">
          </div>
        </div>
        <div class="flex">
          <div class="row">
            <label>Ville</label>
            <input type="text" v-model="contact.ville" placeholder="Ville">
          </div>
          <div class="row">
            <label>Pays</label>
            <input type="text" v-model="contact.pays" placeholder="Pays">
          </div>
        </div>

        <div class="submit">
          <button @click="cancel">Annuler</button>
          <button @click="save">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: null
  },
  data() {
    return {
      contacts: [],
      contact: null,
      search: null,
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    search() {
      if (this.search.length > 3) {
        this.searchRun()
      }

      if (this.search.length === 0) {
        this.load()
      }
    }
  },
  methods: {
    cancel() {
      this.contact = null;
    },
    newContact() {
      this.contact = {
        name: null,
        prenom: null,
        email: null,
        adresse: null,
        cp: null,
        ville: null,
        tel: null,
        pays: null,
        company: null,
        user: "/api/users/" + this.user,
      };
    },
    edit(contact) {
      this.contact = contact;
    },
    searchRun() {
      const filteredContacts = []
      for (const contact of this.contacts) {
        if (contact.name.toLowerCase().includes(this.search.toLowerCase())) {
          filteredContacts.push(contact)
        }
        else {
          if (contact.prenom.toLowerCase().includes(this.search.toLowerCase())) {
            filteredContacts.push(contact)
          }
          else {
            if (contact.company.toLowerCase().includes(this.search.toLowerCase())) {
              filteredContacts.push(contact)
            }
            else {
              if (contact.email.toLowerCase().includes(this.search.toLowerCase())) {
                filteredContacts.push(contact)
              }
            }
          }
        }
      }

      this.contacts = filteredContacts
    },
    load() {
      axios.get(`/api/contacts?user=${this.user}`).then(response => {
        this.contacts = response.data["hydra:member"];
      });
    },
    remove(contact) {
      if (window.confirm("Etes-vous sur de vouloir supprimer ce contact ?")) {
        axios.delete(`/api/contacts/${contact.id}`).then(response => {
          this.load();
        });
      }
    },
    save() {
      if (this.contact.id) {
        axios.put(`/api/contacts/${this.contact.id}`, this.contact).then(response => {
          console.log(response.data);
          this.contact = null;
          this.load();
        });
      } else {
        axios.post('/api/contacts', this.contact).then(response => {
          console.log(response.data);
          this.contact = null;
          this.load();
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-top: 30px;
}

.search {
  padding-top: 20px;
  input {
    border: 1px solid #009fee;
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);

  > div {
    width: 750px;
    height: 550px;
    background: #FFF;
    padding: 20px;
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    //box-shadow: 1px 1px 1px 1px #888888;

    .flex {
      display: flex;
      justify-content: space-between;

      .row {
        width: 48%;
      }
    }

    .row {
      margin-bottom: 20px;
    }

    label {
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #009fee;
    }
  }
}
</style>