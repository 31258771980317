var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ul", { staticClass: "steps" }, [
        _c("li", { class: { active: _vm.step == 1 } }, [
          _c("span", [_vm._v("01")]),
          _vm._v(" "),
          _c("i", [_vm._v("Demandeur")])
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.step == 2 } }, [
          _c("span", [_vm._v("02")]),
          _vm._v(" "),
          _c("i", [_vm._v("Expéditeur")])
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.step == 3 } }, [
          _c("span", [_vm._v("03")]),
          _vm._v(" "),
          _c("i", [_vm._v("Destinataire")])
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.step == 4 } }, [
          _c("span", [_vm._v("04")]),
          _vm._v(" "),
          _c("i", [_vm._v("Contenu")])
        ]),
        _vm._v(" "),
        _c("li", { class: { active: _vm.step == 5 } }, [
          _c("span", [_vm._v("05")]),
          _vm._v(" "),
          _c("i", [_vm._v("Conditions")])
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.step == 1
          ? _c("div", { staticClass: "form-grid" }, [
              _vm.contactLoaded
                ? _c("div", { staticClass: "form-group right" }, [
                    _c("label", [_vm._v("Répertoire :")])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.contactLoaded
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.demandeurPrefillOptions,
                            label: "full"
                          },
                          model: {
                            value: _vm.selectedContact,
                            callback: function($$v) {
                              _vm.selectedContact = $$v
                            },
                            expression: "selectedContact"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurSociete.value,
                      expression: "ship.demandeurSociete.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Nom de la société" },
                  domProps: { value: _vm.ship.demandeurSociete.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurSociete,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurIdPatient.value,
                      expression: "ship.demandeurIdPatient.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "ID Patient" },
                  domProps: { value: _vm.ship.demandeurIdPatient.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurIdPatient,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurNom.value,
                      expression: "ship.demandeurNom.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Nom" },
                  domProps: { value: _vm.ship.demandeurNom.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurNom,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurPrenom.value,
                      expression: "ship.demandeurPrenom.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Prénom" },
                  domProps: { value: _vm.ship.demandeurPrenom.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurPrenom,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurEmail.value,
                      expression: "ship.demandeurEmail.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Email" },
                  domProps: { value: _vm.ship.demandeurEmail.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurEmail,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurTel.value,
                      expression: "ship.demandeurTel.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Téléphone" },
                  domProps: { value: _vm.ship.demandeurTel.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurTel,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurNumeroDemande.value,
                      expression: "ship.demandeurNumeroDemande.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "N°demande interne" },
                  domProps: { value: _vm.ship.demandeurNumeroDemande.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurNumeroDemande,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurCodeProjet.value,
                      expression: "ship.demandeurCodeProjet.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Code projet" },
                  domProps: { value: _vm.ship.demandeurCodeProjet.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurCodeProjet,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.demandeurCentreCout.value,
                      expression: "ship.demandeurCentreCout.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Centre de coût" },
                  domProps: { value: _vm.ship.demandeurCentreCout.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.demandeurCentreCout,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.invoiceNumber.value,
                      expression: "ship.invoiceNumber.value"
                    }
                  ],
                  attrs: { type: "text", placeholder: "Invoice number" },
                  domProps: { value: _vm.ship.invoiceNumber.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.ship.invoiceNumber,
                        "value",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.step == 2
          ? _c("div", {}, [
              _c("div", { staticClass: "prefill" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.copyExpediteur($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Utiliser les mêmes informations que pour le demandeur"
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-grid" }, [
                _vm.contactLoaded
                  ? _c("div", { staticClass: "form-group right" }, [
                      _c("label", [_vm._v("Répertoire :")])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.contactLoaded
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.expediteurPrefillOptions,
                              label: "full"
                            },
                            model: {
                              value: _vm.selectedContact,
                              callback: function($$v) {
                                _vm.selectedContact = $$v
                              },
                              expression: "selectedContact"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurSociete.value,
                        expression: "ship.expediteurSociete.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Nom de la société" },
                    domProps: { value: _vm.ship.expediteurSociete.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurSociete,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurIdPatient.value,
                        expression: "ship.expediteurIdPatient.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "ID patient" },
                    domProps: { value: _vm.ship.expediteurIdPatient.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurIdPatient,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurPrenom.value,
                        expression: "ship.expediteurPrenom.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Prénom" },
                    domProps: { value: _vm.ship.expediteurPrenom.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurPrenom,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurNom.value,
                        expression: "ship.expediteurNom.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Nom" },
                    domProps: { value: _vm.ship.expediteurNom.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurNom,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurService.value,
                        expression: "ship.expediteurService.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Service" },
                    domProps: { value: _vm.ship.expediteurService.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurService,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurBatEtage.value,
                        expression: "ship.expediteurBatEtage.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Bat / Etage" },
                    domProps: { value: _vm.ship.expediteurBatEtage.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurBatEtage,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurAdresse.value,
                        expression: "ship.expediteurAdresse.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Adresse" },
                    domProps: { value: _vm.ship.expediteurAdresse.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurAdresse,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurCodePostal.value,
                        expression: "ship.expediteurCodePostal.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Code postal" },
                    domProps: { value: _vm.ship.expediteurCodePostal.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurCodePostal,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurVille.value,
                        expression: "ship.expediteurVille.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Ville" },
                    domProps: { value: _vm.ship.expediteurVille.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurVille,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurPays.value,
                        expression: "ship.expediteurPays.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Pays" },
                    domProps: { value: _vm.ship.expediteurPays.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurPays,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurEmail.value,
                        expression: "ship.expediteurEmail.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Email" },
                    domProps: { value: _vm.ship.expediteurEmail.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurEmail,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.expediteurTel.value,
                        expression: "ship.expediteurTel.value"
                      }
                    ],
                    attrs: { type: "text", placeholder: "Téléphone" },
                    domProps: { value: _vm.ship.expediteurTel.value },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.expediteurTel,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("datepicker", {
                      attrs: {
                        "disabled-dates": _vm.minDatesExp,
                        "monday-first": true,
                        language: _vm.language,
                        placeholder: "Date d'enlèvement souhaitée"
                      },
                      model: {
                        value: _vm.ship.expediteurDateEnlevement.value,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.ship.expediteurDateEnlevement,
                            "value",
                            $$v
                          )
                        },
                        expression: "ship.expediteurDateEnlevement.value"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Heure d'enlèvement souhaitée")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.ship.expediteurHeureEnlevementSouhaite.value,
                          expression:
                            "ship.expediteurHeureEnlevementSouhaite.value"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ship.expediteurHeureEnlevementSouhaite,
                            "value",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Dans la journée" } }, [
                        _vm._v("Dans la journée")
                      ]),
                      _vm._v(" "),
                      _c(
                        "option",
                        { attrs: { value: "Choisir une plage horaire" } },
                        [_vm._v("Choisir une plage horaire")]
                      ),
                      _vm._v(" "),
                      _c(
                        "option",
                        { attrs: { value: "Définir un horaire précis" } },
                        [_vm._v("Définir un horaire précis")]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.ship.expediteurHeureEnlevementSouhaite.value ===
                "Choisir une plage horaire"
                  ? _c("div", { staticClass: "form-group kg" }, [
                      _c("label", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.ship.expediteurPlageHeureEnlevement.value,
                              expression:
                                "ship.expediteurPlageHeureEnlevement.value"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ship.expediteurPlageHeureEnlevement,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "8h-12h" } }, [
                            _vm._v("8h-12h")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "13h-17h" } }, [
                            _vm._v("13h-17h")
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ship.expediteurHeureEnlevementSouhaite.value ===
                "Définir un horaire précis"
                  ? _c("div", { staticClass: "form-group kg" }, [
                      _c("label", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ship.expediteurHeureEnlevement.value,
                            expression: "ship.expediteurHeureEnlevement.value"
                          }
                        ],
                        attrs: {
                          type: "time",
                          placeholder: "Heure d'enlèvement souhaitée",
                          required: "required"
                        },
                        domProps: {
                          value: _vm.ship.expediteurHeureEnlevement.value
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ship.expediteurHeureEnlevement,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("H")])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.step == 3
        ? _c("div", { staticClass: "form-grid" }, [
            _vm.contactLoaded
              ? _c("div", { staticClass: "form-group right" }, [
                  _c("label", [_vm._v("Répertoire :")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactLoaded
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.destinatairePrefillOptions,
                          label: "full"
                        },
                        model: {
                          value: _vm.selectedContact,
                          callback: function($$v) {
                            _vm.selectedContact = $$v
                          },
                          expression: "selectedContact"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireSociete.value,
                    expression: "ship.destinataireSociete.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Nom de la société" },
                domProps: { value: _vm.ship.destinataireSociete.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireSociete,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireIdPatient.value,
                    expression: "ship.destinataireIdPatient.value"
                  }
                ],
                attrs: { type: "text", placeholder: "ID patient" },
                domProps: { value: _vm.ship.destinataireIdPatient.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireIdPatient,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinatairePrenom.value,
                    expression: "ship.destinatairePrenom.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Prénom" },
                domProps: { value: _vm.ship.destinatairePrenom.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinatairePrenom,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireNom.value,
                    expression: "ship.destinataireNom.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Nom" },
                domProps: { value: _vm.ship.destinataireNom.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireNom,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireService.value,
                    expression: "ship.destinataireService.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Service" },
                domProps: { value: _vm.ship.destinataireService.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireService,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireBatEtage.value,
                    expression: "ship.destinataireBatEtage.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Bat / Etage" },
                domProps: { value: _vm.ship.destinataireBatEtage.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireBatEtage,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireAdresse.value,
                    expression: "ship.destinataireAdresse.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Adresse" },
                domProps: { value: _vm.ship.destinataireAdresse.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireAdresse,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireCodePostal.value,
                    expression: "ship.destinataireCodePostal.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Code postal" },
                domProps: { value: _vm.ship.destinataireCodePostal.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireCodePostal,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireVille.value,
                    expression: "ship.destinataireVille.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Ville" },
                domProps: { value: _vm.ship.destinataireVille.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireVille,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinatairePays.value,
                    expression: "ship.destinatairePays.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Pays" },
                domProps: { value: _vm.ship.destinatairePays.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinatairePays,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireEmail.value,
                    expression: "ship.destinataireEmail.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Email" },
                domProps: { value: _vm.ship.destinataireEmail.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireEmail,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.destinataireTel.value,
                    expression: "ship.destinataireTel.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Téléphone" },
                domProps: { value: _vm.ship.destinataireTel.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.destinataireTel,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("datepicker", {
                  attrs: {
                    "disabled-dates": _vm.minDatesDest,
                    "monday-first": true,
                    language: _vm.language,
                    placeholder: "Date de livraison souhaitée"
                  },
                  model: {
                    value: _vm.ship.destinataireDateLivraison.value,
                    callback: function($$v) {
                      _vm.$set(_vm.ship.destinataireDateLivraison, "value", $$v)
                    },
                    expression: "ship.destinataireDateLivraison.value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Heure de livraison souhaitée")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.destinataireHeureLivraisonSouhaite.value,
                      expression:
                        "ship.destinataireHeureLivraisonSouhaite.value"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.ship.destinataireHeureLivraisonSouhaite,
                        "value",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "Dans la journée" } }, [
                    _vm._v("Dans la journée")
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Choisir une plage horaire" } },
                    [_vm._v("Choisir une plage horaire")]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Définir un horaire précis" } },
                    [_vm._v("Définir un horaire précis")]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.ship.destinataireHeureLivraisonSouhaite.value ===
            "Choisir une plage horaire"
              ? _c("div", { staticClass: "form-group kg" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ship.destinatairePlageHeureLivraison.value,
                          expression:
                            "ship.destinatairePlageHeureLivraison.value"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ship.destinatairePlageHeureLivraison,
                            "value",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "8h-12h" } }, [
                        _vm._v("8h-12h")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "13h-17h" } }, [
                        _vm._v("13h-17h")
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ship.destinataireHeureLivraisonSouhaite.value ===
            "Définir un horaire précis"
              ? _c("div", { staticClass: "form-group kg" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.destinataireHeureLivraison.value,
                        expression: "ship.destinataireHeureLivraison.value"
                      }
                    ],
                    attrs: {
                      type: "time",
                      placeholder: "Heure de livraison souhaitée",
                      required: "required"
                    },
                    domProps: {
                      value: _vm.ship.destinataireHeureLivraison.value
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ship.destinataireHeureLivraison,
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("H")])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 4
        ? _c("div", { staticClass: "form-grid" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.typeProduit.value,
                    expression: "ship.typeProduit.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Type de produit" },
                domProps: { value: _vm.ship.typeProduit.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ship.typeProduit, "value", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.conditionnement.value,
                    expression: "ship.conditionnement.value"
                  }
                ],
                attrs: { type: "text", placeholder: "Conditionnement" },
                domProps: { value: _vm.ship.conditionnement.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.conditionnement,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.nombreConditionnement.value,
                    expression: "ship.nombreConditionnement.value"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "Nombre de conditionnement"
                },
                domProps: { value: _vm.ship.nombreConditionnement.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.nombreConditionnement,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.quantiteConditionnement.value,
                    expression: "ship.quantiteConditionnement.value"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "Quantité par conditionnement"
                },
                domProps: { value: _vm.ship.quantiteConditionnement.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ship.quantiteConditionnement,
                      "value",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 5
        ? _c("div", [
            _c("label", { staticClass: "label" }, [
              _vm._v("Choix de l'emballage")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "radio" }, [
                _vm._v("\n        TMS\n        "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.choixEmballage.value,
                      expression: "ship.choixEmballage.value"
                    }
                  ],
                  attrs: { type: "radio", name: "choice", value: "TMS" },
                  domProps: {
                    checked: _vm._q(_vm.ship.choixEmballage.value, "TMS")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.ship.choixEmballage, "value", "TMS")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "radio" }, [
                _vm._v("\n        Client\n        "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ship.choixEmballage.value,
                      expression: "ship.choixEmballage.value"
                    }
                  ],
                  attrs: { type: "radio", name: "choice", value: "Client" },
                  domProps: {
                    checked: _vm._q(_vm.ship.choixEmballage.value, "Client")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(
                        _vm.ship.choixEmballage,
                        "value",
                        "Client"
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" })
              ])
            ]),
            _vm._v(" "),
            _vm.ship.choixEmballage.value === "TMS"
              ? _c(
                  "div",
                  {
                    staticClass: "form-grid",
                    staticStyle: { "margin-bottom": "30px" }
                  },
                  [
                    _c("div", { staticClass: "form-group kg" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ship.choixEmballageTms.value,
                              expression: "ship.choixEmballageTms.value"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ship.choixEmballageTms,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "TMS 3L" } }, [
                            _vm._v("TMS 3L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "TMS 6L" } }, [
                            _vm._v("TMS 6L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "TMS 14L" } }, [
                            _vm._v("TMS 14L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "TMS 25L" } }, [
                            _vm._v("TMS 25L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "TMS 58L" } }, [
                            _vm._v("TMS 58L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "TMS 180L" } }, [
                            _vm._v("TMS 180L")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Autres" } }, [
                            _vm._v("Autres")
                          ])
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.ship.choixEmballage.value == "Client"
              ? _c(
                  "div",
                  {
                    staticClass: "form-grid",
                    staticStyle: { "margin-bottom": "30px" }
                  },
                  [
                    _c("div", { staticClass: "form-group kg" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ship.poidColis.value,
                            expression: "ship.poidColis.value"
                          }
                        ],
                        attrs: {
                          type: "number",
                          placeholder: "Poid du colis emballé (en Kg)"
                        },
                        domProps: { value: _vm.ship.poidColis.value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ship.poidColis,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Kg")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group kg" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ship.dimensionColis.value,
                            expression: "ship.dimensionColis.value"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "Dimension du colis emballé L x l x h"
                        },
                        domProps: { value: _vm.ship.dimensionColis.value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.ship.dimensionColis,
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("cm")])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("label", { staticClass: "label" }, [_vm._v("Température")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-grid" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "radio" }, [
                  _vm._v("\n          NON\n          "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.suiviTemperature.value,
                        expression: "ship.suiviTemperature.value"
                      }
                    ],
                    attrs: { type: "radio", name: "suiviTemperature" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.ship.suiviTemperature.value, false)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.ship.suiviTemperature,
                          "value",
                          false
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio" }, [
                  _vm._v("\n          OUI\n          "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.suiviTemperature.value,
                        expression: "ship.suiviTemperature.value"
                      }
                    ],
                    attrs: { type: "radio", name: "suiviTemperature" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.ship.suiviTemperature.value, true)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.ship.suiviTemperature,
                          "value",
                          true
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.ship.suiviTemperature.value
              ? _c("label", { staticClass: "label" }, [
                  _vm._v("Choix de la température transport")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-grid",
                staticStyle: { "margin-bottom": "23px" }
              },
              [
                _vm.ship.suiviTemperature.value
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ship.temperatureTransport.value,
                              expression: "ship.temperatureTransport.value"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.ship.temperatureTransport,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("Température de transport")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "-196" } }, [
                            _vm._v("-196°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "-80" } }, [
                            _vm._v("-80°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "-30" } }, [
                            _vm._v("-30°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "-15 / -25" } }, [
                            _vm._v("-15°C / -25°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "+2 / +8" } }, [
                            _vm._v("+2°C / +8°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "+15 / +25" } }, [
                            _vm._v("+15°C / +25°C")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "+37" } }, [
                            _vm._v("+37°C")
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.ship.suiviTemperature.value
              ? _c("div", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("JE SOUHAITE UNE SONDE DE TEMPERATURE")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-grid2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "radio" }, [
                        _vm._v("\n            Non\n            "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ship.temperature.value,
                              expression: "ship.temperature.value"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            value: "Non",
                            name: "temperature"
                          },
                          domProps: {
                            checked: _vm._q(_vm.ship.temperature.value, "Non")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.ship.temperature,
                                "value",
                                "Non"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" })
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _vm._v("\n            Oui - Sonde TMS\n            "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ship.temperature.value,
                              expression: "ship.temperature.value"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            value: "Sonde TMS",
                            name: "temperature"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.ship.temperature.value,
                              "Sonde TMS"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.ship.temperature,
                                "value",
                                "Sonde TMS"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" })
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "radio" }, [
                        _vm._v(
                          "\n            Oui - Sonde Client\n            "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ship.temperature.value,
                              expression: "ship.temperature.value"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            value: "Client",
                            name: "temperature"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.ship.temperature.value,
                              "Client"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.ship.temperature,
                                "value",
                                "Client"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.ship.temperature.value == "Client" ||
                  _vm.ship.temperature.value == "Sonde TMS"
                    ? _c(
                        "div",
                        {
                          staticClass: "form-grid",
                          staticStyle: { "margin-bottom": "30px" }
                        },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ship.idSonde.value,
                                  expression: "ship.idSonde.value"
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: "ID de la sonde"
                              },
                              domProps: { value: _vm.ship.idSonde.value },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ship.idSonde,
                                    "value",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", { staticClass: "label" }, [_vm._v("Véhicule dédié")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-grid" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "radio" }, [
                  _vm._v("\n          NON\n          "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.vehiculeDedie.value,
                        expression: "ship.vehiculeDedie.value"
                      }
                    ],
                    attrs: { type: "radio", name: "vehiculeDedie" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.ship.vehiculeDedie.value, false)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.ship.vehiculeDedie, "value", false)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio" }, [
                  _vm._v("\n          OUI\n          "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ship.vehiculeDedie.value,
                        expression: "ship.vehiculeDedie.value"
                      }
                    ],
                    attrs: { type: "radio", name: "vehiculeDedie" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.ship.vehiculeDedie.value, true)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.ship.vehiculeDedie, "value", true)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              !_vm.isUploading
                ? _c(
                    "label",
                    { staticClass: "label-file", attrs: { for: "files" } },
                    [
                      _c("i", { staticClass: "fas fa-upload" }),
                      _vm._v(" "),
                      _vm.nbFiles === 0
                        ? _c("span", [
                            _vm._v(
                              "Choisir des fichiers - Vous pouvez sélectionner plusieurs fichiers avec la touche Ctrl ou Cmd"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.nbFiles > 0
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.nbFiles + " fichiers sélectionné(s)") +
                                " " +
                                _vm._s(_vm.humanFileSize(_vm.totalSize, true))
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                ref: "files",
                attrs: { type: "file", id: "files", multiple: "" },
                on: {
                  change: function($event) {
                    return _vm.getFiles($event.target.name, $event.target.files)
                  }
                }
              }),
              _vm._v(" "),
              _vm.isUploading ? _c("div", [_vm._m(0)]) : _vm._e(),
              _vm._v(" "),
              _c("label", { staticClass: "label" }, [_vm._v("Commentaire")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ship.comment,
                    expression: "ship.comment"
                  }
                ],
                domProps: { value: _vm.ship.comment },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ship, "comment", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "submit" }, [
        _vm.step > 1
          ? _c("button", { staticClass: "prev", on: { click: _vm.prev } }, [
              _vm._v("Retour")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.step < 5
          ? _c("button", { staticClass: "next", on: { click: _vm.next } }, [
              _vm._v("Suivant")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 5 && !_vm.isLoading
          ? _c(
              "button",
              {
                staticClass: "next",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [_vm._v("\n       Suivant\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 5 && _vm.isLoading
          ? _c("div", { staticClass: "lds-roller final" }, [
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-roller" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }