/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import 'normalize.css';
import 'leaflet/dist/leaflet.css';
// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

const axios = require('axios');
global.axios = axios;

import Vue from 'vue';
window.Vue = Vue;

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

Vue.component('ship', require('./components/Ship.vue').default);
Vue.component('tracker', require('./components/Tracker.vue').default);
Vue.component('contact', require('./components/contact.vue').default);

let elementExists = document.getElementById("account-main-content");
if (elementExists) {
    new Vue({
        el: '#account-main-content',
        data: {
            show: false
        }
    });
}
