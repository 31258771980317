<template>
  <div>
    <ul class="steps">
      <li :class="{ active: (step == 1) }">
        <span>01</span> <i>Demandeur</i>
      </li>
      <li :class="{ active: (step == 2) }">
        <span>02</span> <i>Expéditeur</i>
      </li>
      <li :class="{ active: (step == 3) }">
        <span>03</span> <i>Destinataire</i>
      </li>
      <li :class="{ active: (step == 4) }">
        <span>04</span> <i>Contenu</i>
      </li>
      <li :class="{ active: (step == 5) }">
        <span>05</span> <i>Conditions</i>
      </li>
    </ul>

    <transition name="slide-fade">
      <div class="form-grid" v-if="step == 1">
        <div class="form-group right" v-if="contactLoaded">
            <label>Répertoire :</label>
        </div>
        <div class="form-group" v-if="contactLoaded">
          <div>
            <v-select v-model="selectedContact" :options="demandeurPrefillOptions" label="full"></v-select>
          </div>
        </div>
        <!--
        <div class="form-group" v-if="contacts">
            <select v-model="selectedContact">
                <option :value="contact" v-for="contact in contacts">{{ contact.prenom }} {{ contact.name }}</option>
                <option :value="d" v-for="d in prefill.demandeurs">{{ d.demandeurSociete }} - {{ d.demandeurPrenom }}
                  {{ d.demandeurNom }}
                </option>
            </select>
        </div>

        <div class="form-group right" v-if="prefill.demandeurs">
          <label>Répertoire :</label>
        </div>
        <div class="form-group" v-if="prefill.demandeurs">
          <select v-model="prefillDemandeur">
            <option :value="d" v-for="d in prefill.demandeurs">{{ d.demandeurSociete }} - {{ d.demandeurPrenom }}
              {{ d.demandeurNom }}
            </option>
          </select>
        </div>-->
        <div class="form-group">
          <input type="text" v-model="ship.demandeurSociete.value" placeholder="Nom de la société">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurIdPatient.value" placeholder="ID Patient">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurNom.value" placeholder="Nom">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurPrenom.value" placeholder="Prénom">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurEmail.value" placeholder="Email">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurTel.value" placeholder="Téléphone">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurNumeroDemande.value" placeholder="N°demande interne">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurCodeProjet.value" placeholder="Code projet">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.demandeurCentreCout.value" placeholder="Centre de coût">
        </div>
        <div class="form-group">
          <input type="text" v-model="ship.invoiceNumber.value" placeholder="Invoice number">
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="" v-if="step == 2">
        <div class="prefill">
          <button @click.prevent="copyExpediteur">Utiliser les mêmes informations que pour le demandeur</button>
        </div>
        <div class="form-grid">

          <div class="form-group right" v-if="contactLoaded">
            <label>Répertoire :</label>
          </div>
          <div class="form-group" v-if="contactLoaded">
            <div>
              <v-select v-model="selectedContact" :options="expediteurPrefillOptions" label="full"></v-select>
            </div>
          </div>

          <!--<div class="form-group right" v-if="prefill.expediteurs">
            <label>Répertoire :</label>
          </div>
          <div class="form-group" v-if="prefill.expediteurs">
            <select v-model="prefillExpediteur">
              <option :value="e" v-for="e in prefill.expediteurs">{{ e.expediteurSociete }} - {{ e.expediteurPrenom }}
                {{ e.expediteurNom }}
              </option>
            </select>
          </div>-->
          <!--
          <div class="form-group right" v-if="contacts">
              <label>Répertoire :</label>
          </div>
          <div class="form-group" v-if="contacts">
              <select v-model="selectedContact">
                  <option :value="contact" v-for="contact in contacts">{{ contact.prenom }} {{ contact.name }}</option>
              </select>
          </div>-->
          <div class="form-group">
            <input type="text" v-model="ship.expediteurSociete.value" placeholder="Nom de la société">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurIdPatient.value" placeholder="ID patient">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurPrenom.value" placeholder="Prénom">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurNom.value" placeholder="Nom">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurService.value" placeholder="Service">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurBatEtage.value" placeholder="Bat / Etage">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurAdresse.value" placeholder="Adresse">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurCodePostal.value" placeholder="Code postal">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurVille.value" placeholder="Ville">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurPays.value" placeholder="Pays">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurEmail.value" placeholder="Email">
          </div>
          <div class="form-group">
            <input type="text" v-model="ship.expediteurTel.value" placeholder="Téléphone">
          </div>
          <div class="form-group">
            <datepicker :disabled-dates="minDatesExp" :monday-first="true" :language="language"
                        v-model="ship.expediteurDateEnlevement.value"
                        placeholder="Date d'enlèvement souhaitée"></datepicker>
          </div>
          <div class="form-group"></div>
          <div class="form-group">
            <label>Heure d'enlèvement souhaitée</label>
            <select v-model="ship.expediteurHeureEnlevementSouhaite.value">
              <option value="Dans la journée">Dans la journée</option>
              <option value="Choisir une plage horaire">Choisir une plage horaire</option>
              <option value="Définir un horaire précis">Définir un horaire précis</option>
            </select>
          </div>
          <div class="form-group kg" v-if="ship.expediteurHeureEnlevementSouhaite.value === 'Choisir une plage horaire'">
            <label>&nbsp;</label>
            <select v-model="ship.expediteurPlageHeureEnlevement.value">
              <option value="8h-12h">8h-12h</option>
              <option value="13h-17h">13h-17h</option>
            </select>
          </div>
          <div class="form-group kg" v-if="ship.expediteurHeureEnlevementSouhaite.value === 'Définir un horaire précis'">
            <label>&nbsp;</label>
            <input type="time" v-model="ship.expediteurHeureEnlevement.value" placeholder="Heure d'enlèvement souhaitée"
                   required="required">
            <span>H</span>
          </div>
        </div>
      </div>
    </transition>

    <div class="form-grid" v-if="step == 3">
      <div class="form-group right" v-if="contactLoaded">
        <label>Répertoire :</label>
      </div>
      <div class="form-group" v-if="contactLoaded">
        <div>
          <v-select v-model="selectedContact" :options="destinatairePrefillOptions" label="full"></v-select>
        </div>
      </div>
      <!--
      <div class="form-group right" v-if="contacts">
          <label>Répertoire :</label>
      </div>
      <div class="form-group" v-if="contacts">
          <select v-model="selectedContact">
              <option :value="contact" v-for="contact in contacts">{{ contact.prenom }} {{ contact.name }}</option>
          </select>
      </div>

      <div class="form-group right" v-if="prefill.destinataires">
        <label>Répertoire :</label>
      </div>
      <div class="form-group" v-if="prefill.destinataires">
        <select v-model="prefillDestinataire">
          <option :value="d" v-for="d in prefill.destinataires">{{ d.destinataireSociete }} - {{ d.destinatairePrenom }}
            {{ d.destinataireNom }}
          </option>
        </select>
      </div>-->
      <div class="form-group">
        <input type="text" v-model="ship.destinataireSociete.value" placeholder="Nom de la société">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireIdPatient.value" placeholder="ID patient">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinatairePrenom.value" placeholder="Prénom">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireNom.value" placeholder="Nom">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireService.value" placeholder="Service">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireBatEtage.value" placeholder="Bat / Etage">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireAdresse.value" placeholder="Adresse">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireCodePostal.value" placeholder="Code postal">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireVille.value" placeholder="Ville">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinatairePays.value" placeholder="Pays">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireEmail.value" placeholder="Email">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.destinataireTel.value" placeholder="Téléphone">
      </div>
      <div class="form-group">
        <datepicker :disabled-dates="minDatesDest" :monday-first="true" :language="language"
                    v-model="ship.destinataireDateLivraison.value"
                    placeholder="Date de livraison souhaitée"></datepicker>
      </div>

      <div class="form-group"></div>
      <div class="form-group">
        <label>Heure de livraison souhaitée</label>
        <select v-model="ship.destinataireHeureLivraisonSouhaite.value">
          <option value="Dans la journée">Dans la journée</option>
          <option value="Choisir une plage horaire">Choisir une plage horaire</option>
          <option value="Définir un horaire précis">Définir un horaire précis</option>
        </select>
      </div>
      <div class="form-group kg" v-if="ship.destinataireHeureLivraisonSouhaite.value === 'Choisir une plage horaire'">
        <label>&nbsp;</label>
        <select v-model="ship.destinatairePlageHeureLivraison.value">
          <option value="8h-12h">8h-12h</option>
          <option value="13h-17h">13h-17h</option>
        </select>
      </div>
      <div class="form-group kg" v-if="ship.destinataireHeureLivraisonSouhaite.value === 'Définir un horaire précis'">
        <label>&nbsp;</label>
        <input type="time" v-model="ship.destinataireHeureLivraison.value" placeholder="Heure de livraison souhaitée"
               required="required">
        <span>H</span>
      </div>
    </div>

    <div class="form-grid" v-if="step == 4">
      <div class="form-group">
        <input type="text" v-model="ship.typeProduit.value" placeholder="Type de produit">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.conditionnement.value" placeholder="Conditionnement">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.nombreConditionnement.value" placeholder="Nombre de conditionnement">
      </div>
      <div class="form-group">
        <input type="text" v-model="ship.quantiteConditionnement.value" placeholder="Quantité par conditionnement">
      </div>
    </div>

    <div v-if="step == 5">
      <label class="label">Choix de l'emballage</label>
      <div class="form-group">
        <label class="radio">
          TMS
          <input type="radio" name="choice" value="TMS" v-model="ship.choixEmballage.value">
          <span class="checkmark"></span>
        </label>
        <label class="radio">
          Client
          <input type="radio" name="choice" value="Client" v-model="ship.choixEmballage.value">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="form-grid" v-if="ship.choixEmballage.value === 'TMS'" style="margin-bottom: 30px">
        <div class="form-group kg">
          <select v-model="ship.choixEmballageTms.value">
            <option value="TMS 3L">TMS 3L</option>
            <option value="TMS 6L">TMS 6L</option>
            <option value="TMS 14L">TMS 14L</option>
            <option value="TMS 25L">TMS 25L</option>
            <option value="TMS 58L">TMS 58L</option>
            <option value="TMS 180L">TMS 180L</option>
            <option value="Autres">Autres</option>
          </select>
        </div>
      </div>
      <div class="form-grid" v-if="ship.choixEmballage.value == 'Client'" style="margin-bottom: 30px">
        <div class="form-group kg">
          <input type="number" v-model="ship.poidColis.value" placeholder="Poid du colis emballé (en Kg)">
          <span>Kg</span>
        </div>
        <div class="form-group kg">
          <input type="text" v-model="ship.dimensionColis.value" placeholder="Dimension du colis emballé L x l x h">
          <span>cm</span>
        </div>
      </div>

      <label class="label">Température</label>
      <div class="form-grid">
        <div class="form-group">
          <label class="radio">
            NON
            <input type="radio" :value="false" name="suiviTemperature" v-model="ship.suiviTemperature.value">
            <span class="checkmark"></span>
          </label>
          <label class="radio">
            OUI
            <input type="radio" :value="true" name="suiviTemperature" v-model="ship.suiviTemperature.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <label class="label" v-if="ship.suiviTemperature.value">Choix de la température transport</label>
      <div class="form-grid" style="margin-bottom: 23px">
        <div class="form-group" v-if="ship.suiviTemperature.value">
          <select v-model="ship.temperatureTransport.value">
            <option :value="null">Température de transport</option>
            <option value="-196">-196°C</option>
            <option value="-80">-80°C</option>
            <option value="-30">-30°C</option>
            <option value="-15 / -25">-15°C / -25°C</option>
            <option value="+2 / +8">+2°C / +8°C</option>
            <option value="+15 / +25">+15°C / +25°C</option>
            <option value="+37">+37°C</option>
          </select>
        </div>
      </div>

      <div v-if="ship.suiviTemperature.value">
        <label class="label">JE SOUHAITE UNE SONDE DE TEMPERATURE</label>
        <div class="form-grid2">
          <div class="form-group">
            <label class="radio">
              Non
              <input type="radio" value="Non" name="temperature" v-model="ship.temperature.value">
              <span class="checkmark"></span>
            </label>
            <label class="radio">
              Oui - Sonde TMS
              <input type="radio" value="Sonde TMS" name="temperature" v-model="ship.temperature.value">
              <span class="checkmark"></span>
            </label>
            <label class="radio">
              Oui - Sonde Client
              <input type="radio" value="Client" name="temperature" v-model="ship.temperature.value">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div class="form-grid" v-if="ship.temperature.value == 'Client' || ship.temperature.value == 'Sonde TMS'"
             style="margin-bottom: 30px;">
          <div class="form-group">
            <input type="text" v-model="ship.idSonde.value" placeholder="ID de la sonde">
          </div>
        </div>
      </div>

      <label class="label">Véhicule dédié</label>
      <div class="form-grid">
        <div class="form-group">
          <label class="radio">
            NON
            <input type="radio" :value="false" name="vehiculeDedie" v-model="ship.vehiculeDedie.value">
            <span class="checkmark"></span>
          </label>
          <label class="radio">
            OUI
            <input type="radio" :value="true" name="vehiculeDedie" v-model="ship.vehiculeDedie.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="files" class="label-file" v-if="!isUploading">
          <i class="fas fa-upload"></i>
          <span v-if="nbFiles === 0">Choisir des fichiers - Vous pouvez sélectionner plusieurs fichiers avec la touche Ctrl ou Cmd</span>
          <span v-if="nbFiles > 0">{{ `${nbFiles} fichiers sélectionné(s)` }} {{
              humanFileSize(totalSize, true)
            }}</span>
        </label>
        <input type="file" ref="files" id="files" @change="getFiles($event.target.name, $event.target.files)" multiple>

        <div v-if="isUploading">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <label class="label">Commentaire</label>
        <textarea v-model="ship.comment"></textarea>
      </div>

    </div>

    <div class="submit">
      <button class="prev" @click="prev" v-if="step > 1">Retour</button>
      <button class="next" @click="next" v-if="step < 5">Suivant</button>
      <button class="next" @click="save()" v-if="step === 5 && !isLoading">
         Suivant
      </button>
      <div class="lds-roller final" v-if="step === 5 && isLoading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VueSingleSelect from 'vue-single-select';
import * as lang from "vuejs-datepicker/src/locale";

var moment = require('moment');
import Toastr from 'toastr';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  props: {
    user: null,
    clone: null
  },
  components: {
    Datepicker,
    VueSingleSelect,
    vSelect
  },
  data() {
    return {
      contacts: [],
      selectedContact: null,
      prefill: [],
      prefillDestinataire: null,
      prefillExpediteur: null,
      prefillDemandeur: null,
      language: lang['fr'],
      step: 1,
      nbFiles: 0,
      totalSize: 0,
      isUploading: false,
      isLoading: false,
      minDatesDest: {
        to: new Date(Date.now() - 864e5)
      },
      minDatesExp: {
        to: new Date(Date.now() - 864e5)
      },
      errors: [],
      ship: {
        suiviTemperature: {
          required: false,
          value: false,
          step: 5,
          erreur: 'Le champ suivi de temperature est obligatoire'
        },
        idSonde: {
          required: false,
          value: null,
          step: 5,
          erreur: 'Le champ id sonde est obligatoire'
        },
        demandeurSociete: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ société est obligatoire'
        },
        demandeurFiles: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ société est obligatoire'
        },
        demandeurNom: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ nom est obligatoire'
        },
        demandeurPrenom: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ prénom est obligatoire'
        },
        demandeurEmail: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ email est obligatoire et doit être une adresse email valide.'
        },
        demandeurTel: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ téléphone est obligatoire'
        },
        demandeurNumeroDemande: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ numéro de demande est obligatoire'
        },
        demandeurCodeProjet: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ code projet est obligatoire'
        },
        demandeurCentreCout: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ centre de coût est obligatoire'
        },
        demandeurIdPatient: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ ID Patient est obligatoire'
        },
        expediteurSociete: {
          required: false,
          value: null,
          step: 2,
          erreur: 'Le champ société est obligatoire'
        },
        expediteurIdPatient: {
          required: false,
          value: null,
          step: 2,
          erreur: 'Le champ ID Patient est obligatoire'
        },
        expediteurService: {
          required: false,
          value: null,
          step: 2,
          erreur: 'Le champ service est obligatoire'
        },
        expediteurNom: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ nom est obligatoire'
        },
        expediteurPrenom: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ prénom est obligatoire'
        },
        expediteurBatEtage: {
          required: false,
          value: null,
          step: 2,
          erreur: 'Le champ bat / étage est obligatoire'
        },
        expediteurAdresse: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ adresse est obligatoire'
        },
        expediteurCodePostal: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ code postal est obligatoire'
        },
        expediteurVille: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ ville est obligatoire'
        },
        expediteurPays: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ pays est obligatoire'
        },
        expediteurTel: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ téléphone est obligatoire'
        },
        expediteurEmail: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ email est obligatoire et doit être une adresse email valide.'
        },
        expediteurDateEnlevement: {
          required: true,
          value: null,
          step: 2,
          erreur: 'Le champ date d\'enlèvement est obligatoire'
        },
        expediteurHeureEnlevementSouhaite: {
          required: true,
          value: 'Définir un horaire précis',
          step: 2,
          erreur: 'Le champ heure d\'enlèvement souhaité est obligatoire'
        },
        expediteurPlageHeureEnlevement: {
          required: false,
          value: '8h-12h',
          step: 2,
          erreur: ''
        },
        expediteurHeureEnlevement: {
          required: false,
          value: null,
          step: 2,
          erreur: 'Le champ heure d\'enlèvment est obligatoire'
        },
        destinataireSociete: {
          required: false,
          value: null,
          step: 3,
          erreur: 'Le champ société est obligatoire'
        },
        destinataireService: {
          required: false,
          value: null,
          step: 3,
          erreur: 'Le champ service est obligatoire'
        },
        destinataireNom: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ nom est obligatoire'
        },
        destinatairePrenom: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ prénom est obligatoire'
        },
        destinataireBatEtage: {
          required: false,
          value: null,
          step: 3,
          erreur: 'Le champ bat / étage est obligatoire'
        },
        destinataireAdresse: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ adresse est obligatoire'
        },
        destinataireCodePostal: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ code postal est obligatoire'
        },
        destinataireVille: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ ville est obligatoire'
        },
        destinatairePays: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ pays est obligatoire'
        },
        destinataireTel: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ téléphone est obligatoire'
        },
        destinataireEmail: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ email est obligatoire et doit être une adresse email valide.'
        },
        destinataireDateLivraison: {
          required: true,
          value: null,
          step: 3,
          erreur: 'Le champ date de livraison est obligatoire'
        },
        destinataireHeureLivraisonSouhaite: {
          required: true,
          value: 'Définir un horaire précis',
          step: 2,
          erreur: 'Le champ heure de livraison souhaité est obligatoire'
        },
        destinatairePlageHeureLivraison: {
          required: false,
          value: '8h-12h',
          step: 2,
          erreur: ''
        },
        destinataireHeureLivraison: {
          required: false,
          value: null,
          step: 3,
          erreur: 'Le champ heure de livraison est obligatoire'
        },
        destinataireIdPatient: {
          required: false,
          value: null,
          step: 3,
          erreur: ''
        },
        typeProduit: {
          required: true,
          value: null,
          step: 4,
          erreur: 'Le champ type de produit est obligatoire'
        },
        conditionnement: {
          required: true,
          value: null,
          step: 4,
          erreur: 'Le champ conditionnement est obligatoire'
        },
        nombreConditionnement: {
          required: true,
          value: null,
          step: 4,
          erreur: 'Le champ nombre de conditionnement est obligatoire'
        },
        quantiteConditionnement: {
          required: true,
          value: null,
          step: 4,
          erreur: 'Le champ quantité de conditionnement est obligatoire'
        },
        choixEmballage: {
          required: true,
          value: "TMS",
          step: 5,
          erreur: 'Le champ choix emballage est obligatoire'
        },
        choixEmballageTms: {
          required: false,
          value: "TMS 25L",
          step: 5,
          erreur: 'Le champ choix emballage TMS est obligatoire'
        },
        poidColis: {
          required: false,
          value: null,
          step: 5,
          erreur: 'Le champ poid est obligatoire'
        },
        dimensionColis: {
          required: false,
          value: null,
          step: 5,
          erreur: 'Le champ dimension est obligatoire'
        },
        temperature: {
          required: true,
          value: "Sonde TMS",
          step: 5,
          erreur: 'Le champ temperature est obligatoire'
        },
        temperatureTransport: {
          required: false,
          value: null,
          step: 5,
          erreur: 'Le champ temperature de transport est obligatoire'
        },
        vehiculeDedie: {
          required: false,
          value: false,
          step: 5,
          erreur: 'Le champ temperature de transport est obligatoire'
        },
        invoiceNumber: {
          required: false,
          value: null,
          step: 1,
          erreur: 'Le champ temperature de transport est obligatoire'
        },
        coment: {
          required: false,
          value: null,
          step: 5,
          erreur: null
        }
      },
      contactLoaded: false
    }
  },
  mounted() {
    axios.get('/prefill').then(response => {
      let prefills = JSON.parse(response.data);
      this.prefill = prefills;

      axios.get(`/api/contacts?user=${this.user}`).then(response => {
        this.contacts = response.data["hydra:member"];
        this.contactLoaded = true
      });
    });

    if (this.clone) {
      axios.get(`/shipinfo/${this.clone}`).then(response => {
        console.log(response.data)

        if (response.data) {
          const d = response.data
          this.ship.demandeurSociete.value = d.demandeurSociete
          this.ship.demandeurIdPatient.value = d.demandeurIdPatient
          this.ship.demandeurNom.value = d.demandeurNom
          this.ship.demandeurPrenom.value = d.demandeurPrenom
          this.ship.demandeurEmail.value = d.demandeurEmail
          this.ship.demandeurNumeroDemande.value = d.demandeurNumeroDemande
          this.ship.demandeurTel.value = d.demandeurPhone
          this.ship.demandeurCodeProjet.value = d.demandeurCodeProjet
          this.ship.demandeurCentreCout.value = d.demandeurCentreCout
          this.ship.invoiceNumber.value = d.invoiceNumber

          this.ship.expediteurSociete.value = d.expediteurSociete
          this.ship.expediteurIdPatient.value = d.expediteurIdPatient
          this.ship.expediteurPrenom.value = d.expediteurPrenom
          this.ship.expediteurNom.value = d.expediteurNom
          this.ship.expediteurService.value = d.expediteurService
          this.ship.expediteurBatEtage.value = d.expediteurBatEtage
          this.ship.expediteurAdresse.value = d.expediteurAdresse
          this.ship.expediteurCodePostal.value = d.expediteurCodePostal
          this.ship.expediteurVille.value = d.expediteurVille
          this.ship.expediteurPays.value = d.expediteurPays
          this.ship.expediteurEmail.value = d.expediteurEmail
          this.ship.expediteurTel.value = d.expediteurTel
          //this.ship.expediteurDateEnlevement.value = d.expediteurDateEnlevement
          //this.ship.expediteurHeureEnlevement.value = d.expediteurHeureEnlevement

          this.ship.destinataireSociete.value = d.destinataireSociete
          this.ship.destinataireIdPatient.value = d.destinataireIdPatient
          this.ship.destinatairePrenom.value = d.destinatairePrenom
          this.ship.destinataireNom.value = d.destinataireNom
          this.ship.destinataireService.value = d.destinataireService
          this.ship.destinataireBatEtage.value = d.destinataireBatEtage
          this.ship.destinataireAdresse.value = d.destinataireAdresse
          this.ship.destinataireCodePostal.value = d.destinataireCodePostal
          this.ship.destinataireVille.value = d.destinataireVille
          this.ship.destinatairePays.value = d.destinatairePays
          this.ship.destinataireEmail.value = d.destinataireEmail
          this.ship.destinataireTel.value = d.destinataireTelephone

          this.ship.typeProduit.value = d.typeProduit
          this.ship.conditionnement.value = d.conditionnement
          this.ship.nombreConditionnement.value = d.nombreConditionnement
          this.ship.quantiteConditionnement.value = d.quantiteParConditionnement

          this.ship.choixEmballage.value = d.choixEmballage
          this.ship.temperature.value = d.temperature
          this.ship.temperatureTransport.value = d.temperatureTransport
          this.ship.suiviTemperature.value = d.suiviTemperature
          this.ship.idSonde.value = d.idSonde
          this.ship.vehiculeDedie.value = d.vehiculeDedie
        }
      });
    }

    //this.loadDemoValue()
  },
  methods: {
    humanFileSize(bytes, si) {
      let thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
      let units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    },
    getFiles(fieldName, fileList) {
      this.nbFiles = 0;
      this.ship.demandeurFiles.value = this.$refs.files.files;
      if (!fileList.length) return;

      this.totalSize = 0;

      Array.from(Array(fileList.length).keys()).map(x => {
        this.totalSize = this.totalSize + fileList[x].size;
        this.nbFiles++;
      });

      if (this.totalSize / (1000 * 1000) > 8) {
        alert('Vos fichiers dépassent la taille maximum de 8Mo.');
        this.nbFiles = 0;
        this.totalSize = 0;
        this.ship.demandeurFiles.value = null;
      }
    },
    next() {
      this.checkForErrors(this.step);
      if (this.errors.length === 0) {
        this.step++;
      } else {
        this.errors.forEach(error => {
          Toastr.error(error);
        });
      }
    },
    prev() {
      this.step--;
    },
    checkForErrors(step) {
      console.log("Error for step => " + step);

      this.errors = [];
      Object.keys(this.ship).forEach(key => {
        let field = this.ship[key];
        if (field.step === step) {
          if (field.required) {
            if (!field.value) {
              this.errors.push(field.erreur);
            }
          }
        }
      });

      if (this.step === 2) {
        if (this.ship.expediteurHeureEnlevementSouhaite.value === 'Définir un horaire précis') {
          if (!this.ship.expediteurHeureEnlevement.value) {
            this.errors.push("Le champ heure d'enlèvement souhaitée est obligatoire");
          }
        }
      }

      if (this.step === 3) {
        if (this.ship.destinataireHeureLivraisonSouhaite.value === 'Définir un horaire précis') {
          if (!this.ship.destinataireHeureLivraison.value) {
            this.errors.push("Le champ heure de livraison souhaitée est obligatoire");
          }
        }
      }

      if (this.step >= 3) {
        if (this.ship.expediteurDateEnlevement.value &&
            this.ship.expediteurHeureEnlevement.value &&
            this.ship.destinataireDateLivraison.value &&
            this.ship.destinataireHeureLivraison.value
        ) {
          let expDate = moment(moment(this.ship.expediteurDateEnlevement.value).format('YYYY-MM-DD') + ' ' + this.ship.expediteurHeureEnlevement.value);
          let destDate = moment(moment(this.ship.destinataireDateLivraison.value).format('YYYY-MM-DD') + ' ' + this.ship.destinataireHeureLivraison.value);

          if (destDate <= expDate) {
            this.errors.push("La date de livraison ne peut pas être inférieure à la date d'expédition");
          }
        }
      }

      if (step === 5) {
        if (this.ship.choixEmballage.value === 'Client' && !this.ship.poidColis.value) {
          this.errors.push(this.ship.poidColis.erreur);
        }

        if (this.ship.poidColis.value) {
          if (this.ship.poidColis.value <= 0) {
            this.errors.push("Le poid doit être positif");
          }
        }

        if (this.ship.suiviTemperature.value && !this.ship.temperatureTransport.value) {
          this.errors.push(this.ship.temperatureTransport.erreur);
        }

        if (this.ship.choixEmballage.value === 'Client') {
          this.ship.choixEmballageTms.value = null
        }
      }
    },
    copyExpediteur() {
      this.ship.expediteurSociete.value = this.ship.demandeurSociete.value;
      this.ship.expediteurNom.value = this.ship.demandeurNom.value;
      this.ship.expediteurPrenom.value = this.ship.demandeurPrenom.value;
      this.ship.expediteurEmail.value = this.ship.demandeurEmail.value;
      this.ship.expediteurTel.value = this.ship.demandeurTel.value;
    },
    loadDemoValue() {
      this.ship.demandeurSociete.value = "F+";
      this.ship.demandeurNom.value = "Rosset";
      this.ship.demandeurPrenom.value = "Arnaud";
      this.ship.demandeurEmail.value = "ar@fplusagency.com";
      this.ship.demandeurTel.value = "0606060606";
      this.ship.demandeurNumeroDemande.value = "122334";
      this.ship.demandeurCodeProjet.value = "AZERT";
      this.ship.demandeurCentreCout.value = "AZERTY";
      this.ship.expediteurSociete.value = "F+";
      this.ship.expediteurService.value = "Direction";
      this.ship.expediteurIdPatient.value = "1234";
      this.ship.expediteurNom.value = "Rosset";
      this.ship.expediteurPrenom.value = "Arnaud";
      this.ship.expediteurBatEtage.value = "N/A";
      this.ship.expediteurAdresse.value = "114 avenue de l'empreur";
      this.ship.expediteurCodePostal.value = "92600";
      this.ship.expediteurVille.value = "Asnieres";
      this.ship.expediteurPays.value = "France";
      this.ship.expediteurTel.value = "0606060606";
      this.ship.expediteurEmail.value = "ar@fplusagency.com";
      this.ship.expediteurDateEnlevement.value = "2019-09-19";
      //this.ship.expediteurHeureEnlevement.value = "14:05";
      this.ship.destinataireSociete.value = "OpenAccess";
      this.ship.destinataireService.value = "Test";
      this.ship.destinataireNom.value = "d'Espeuilles";
      this.ship.destinatairePrenom.value = "Maxence";
      this.ship.destinataireBatEtage.value = "N/A";
      this.ship.destinataireAdresse.value = "4 rue de la mac";
      this.ship.destinataireCodePostal.value = "92600";
      this.ship.destinataireVille.value = "Asnières sur Seine";
      this.ship.destinatairePays.value = "France";
      this.ship.destinataireTel.value = "0606060606";
      this.ship.destinataireEmail.value = "mde@fplusagency.com";
      this.ship.destinataireDateLivraison.value = "2019-09-19";
      //this.ship.destinataireHeureLivraison.value = "16:00";
      this.ship.typeProduit.value = "Organe";
      this.ship.conditionnement.value = "Sac poubelle";
      this.ship.nombreConditionnement.value = "1";
      this.ship.conditionnement.value = "2";
      this.ship.quantiteConditionnement.value = "2";
      this.ship.choixEmballage.value = "TMS";
      this.ship.temperature.value = "Sonde TMS";
    },
    save() {
      this.checkForErrors(this.step);
      if (this.errors.length === 0) {
        this.isLoading = true

        if (!this.ship.expediteurHeureEnlevement.value) this.ship.expediteurHeureEnlevement.value = '00:00'
        if (!this.ship.destinataireHeureLivraison.value) this.ship.destinataireHeureLivraison.value = '00:00'

        if (this.ship.expediteurHeureEnlevementSouhaite.value !== 'Choisir une plage horaire') {
          this.ship.expediteurPlageHeureEnlevement.value = null
        }

        if (this.ship.destinataireHeureLivraisonSouhaite.value !== 'Choisir une plage horaire') {
          this.ship.destinatairePlageHeureLivraison.value = null
        }

        axios.post('/api/ships', {
          "demandeurSociete": this.ship.demandeurSociete.value,
          "demandeurIdPatient": this.ship.demandeurIdPatient.value,
          "demandeurNom": this.ship.demandeurNom.value,
          "demandeurPrenom": this.ship.demandeurPrenom.value,
          "demandeurEmail": this.ship.demandeurEmail.value,
          "demandeurPhone": this.ship.demandeurTel.value,
          "demandeurNumeroDemande": this.ship.demandeurNumeroDemande.value,
          "demandeurCodeProjet": this.ship.demandeurCodeProjet.value,
          "demandeurCentreCout": this.ship.demandeurCentreCout.value,
          "expediteurSociete": this.ship.expediteurSociete.value,
          "expediteurIdPatient": this.ship.expediteurIdPatient.value,
          "expediteurService": this.ship.expediteurService.value,
          "expediteurNom": this.ship.expediteurNom.value,
          "expediteurPrenom": this.ship.expediteurPrenom.value,
          "expediteurBatEtage": this.ship.expediteurBatEtage.value,
          "expediteurAdresse": this.ship.expediteurAdresse.value,
          "expediteurCodePostal": this.ship.expediteurCodePostal.value,
          "expediteurVille": this.ship.expediteurVille.value,
          "expediteurPays": this.ship.expediteurPays.value,
          "expediteurTel": this.ship.expediteurTel.value,
          "expediteurEmail": this.ship.expediteurEmail.value,
          "expediteurDateEnlevement": this.formatedExpDate,
          "expediteurHeureEnlevement": this.ship.expediteurHeureEnlevement.value,
          "expediteurPlageHeureEnlevement": this.ship.expediteurPlageHeureEnlevement.value,
          "expediteurHeureEnlevementSouhaite": this.ship.expediteurHeureEnlevementSouhaite.value,
          "destinataireSociete": this.ship.destinataireSociete.value,
          "destinataireService": this.ship.destinataireService.value,
          "destinataireNom": this.ship.destinataireNom.value,
          "destinatairePrenom": this.ship.destinatairePrenom.value,
          "destinataireBatEtage": this.ship.destinataireBatEtage.value,
          "destinataireAdresse": this.ship.destinataireAdresse.value,
          "destinataireCodePostal": this.ship.destinataireCodePostal.value,
          "destinataireVille": this.ship.destinataireVille.value,
          "destinatairePays": this.ship.destinatairePays.value,
          "destinataireTelephone": this.ship.destinataireTel.value,
          "destinataireEmail": this.ship.destinataireEmail.value,
          "destinataireDateLivraison": this.formatedDestDate,
          "destinataireHeureLivraison": this.ship.destinataireHeureLivraison.value,
          "destinataireHeureLivraisonSouhaite": this.ship.destinataireHeureLivraisonSouhaite.value,
          "destinatairePlageHeureLivraison": this.ship.destinatairePlageHeureLivraison.value,
          "destinataireIdPatient": this.ship.destinataireIdPatient.value,
          "typeProduit": this.ship.typeProduit.value,
          "conditionnement": this.ship.conditionnement.value,
          "nombreConditionnement": this.ship.nombreConditionnement.value,
          "quantiteParConditionnement": this.ship.quantiteConditionnement.value,
          "choixEmballage": this.ship.choixEmballage.value,
          "choixEmballageTms": this.ship.choixEmballageTms.value,
          "poid": parseFloat(this.ship.poidColis.value),
          "dimension": parseFloat(this.ship.dimensionColis.value),
          "temperature": this.ship.temperature.value,
          "temperatureTransport": this.ship.temperatureTransport.value,
          "suiviTemperature": this.ship.suiviTemperature.value,
          "idSonde": this.ship.idSonde.value,
          "vehiculeDedie": this.ship.vehiculeDedie.value,
          "invoiceNumber": this.ship.invoiceNumber.value,
          "user": "/api/users/" + this.user,
          "comment": this.ship.comment
        }).then(response => {
          let shipId = response.data.id;
          let shipCode = response.data.shipCode;
          if (!this.ship.demandeurFiles.value) {
            window.location = '/ship/confirmation/' + response.data.shipCode;
            this.isLoading = false
          } else {
            let formData = new FormData();
            formData.append('shipId', shipId);

            // append the files to FormData
            Array.from(Array(this.ship.demandeurFiles.value.length).keys()).map(x => {
              formData.append('clientFiles[]', this.ship.demandeurFiles.value[x], this.ship.demandeurFiles.value[x].name);
            });

            this.isUploading = true;
            axios.post("/client-file-upload", formData).then(response => {
              window.location = '/ship/confirmation/' + shipCode;
              this.isLoading = false
            }).catch(error => {
              window.location = '/ship/confirmation/' + shipCode;
              this.isLoading = false
            });

            this.isLoading = false
          }
        });
      } else {
        this.errors.forEach(error => {
          Toastr.error(error);
        });
      }
    }
  },
  computed: {
    formatedExpDate() {
      return moment(this.ship.expediteurDateEnlevement.value).format('YYYY-MM-DD');
    },
    formatedDestDate() {
      return moment(this.ship.destinataireDateLivraison.value).format('YYYY-MM-DD');
    },
    destinatairePrefillOptions() {
      let contacts = []

      let i = 0
      for (const contact of this.contacts) {
        contact.full = `${contact.company} - ${contact.name} - ${contact.prenom}`
        contact.id = i
        contacts.push(contact)
        i++
      }

      for (const contact of this.prefill.destinataires) {
        contacts.push({
          id: i,
          full: `${contact.destinataireSociete} - ${contact.destinataireNom} - ${contact.destinatairePrenom}`,
          adresse: contact.destinataireAdresse,
          company: contact.destinataireSociete,
          cp: contact.destinataireCodePostal,
          email: contact.destinataireEmail,
          name: contact.destinataireNom,
          pays: contact.destinatairePays,
          poste: null,
          prenom: contact.destinatairePrenom,
          tel: contact.destinataireTelephone,
          ville: contact.destinataireVille
        })
        i++
      }

      return contacts
    },
    expediteurPrefillOptions() {
      let contacts = []

      let i = 0
      for (const contact of this.contacts) {
        contact.full = `${contact.company} - ${contact.name} - ${contact.prenom}`
        contact.id = i
        contacts.push(contact)
      }

      for (const contact of this.prefill.expediteurs) {
        contacts.push({
          id: i,
          full: `${contact.expediteurSociete} - ${contact.expediteurNom} - ${contact.expediteurPrenom}`,
          adresse: contact.expediteurAdresse,
          company: contact.expediteurSociete,
          cp: contact.expediteurCodePostal,
          email: contact.expediteurEmail,
          name: contact.expediteurNom,
          pays: contact.expediteurPays,
          poste: null,
          prenom: contact.expediteurPrenom,
          tel: contact.expediteurTel,
          ville: contact.expediteurVille
        })
        i++
      }

      return contacts
    },
    demandeurPrefillOptions() {
      let contacts = []
      let i = 0
      for (const contact of this.contacts) {
        contact.id = i
        contact.full = `${contact.company} - ${contact.name} - ${contact.prenom}`
        contacts.push(contact)
      }

      for (const contact of this.prefill.demandeurs) {
        contacts.push({
          id: i,
          full: `${contact.demandeurSociete} - ${contact.demandeurNom} - ${contact.demandeurPrenom}`,
          adresse: null,
          company: contact.demandeurSociete,
          cp: null,
          email: contact.demandeurEmail,
          name: contact.demandeurNom,
          pays: null,
          poste: null,
          prenom: contact.demandeurPrenom,
          tel: contact.demandeurPhone,
          ville: null
        })
        i++
      }

      return contacts
    }
  },
  watch: {
    'ship.suiviTemperature.value'() {
      if (!this.ship.suiviTemperature.value) {
        this.ship.temperatureTransport.value = null
        this.ship.idSonde.value = null
      }
    },
    'ship.expediteurDateEnlevement.value'() {
      this.minDatesDest.to = this.ship.expediteurDateEnlevement.value;
    },
    prefillDestinataire() {
      this.ship.destinataireSociete.value = this.prefillDestinataire.destinataireSociete;
      this.ship.destinataireService.value = this.prefillDestinataire.destinataireService;
      this.ship.destinataireNom.value = this.prefillDestinataire.destinataireNom;
      this.ship.destinatairePrenom.value = this.prefillDestinataire.destinatairePrenom;
      this.ship.destinataireEmail.value = this.prefillDestinataire.destinataireEmail;
      this.ship.destinataireTel.value = this.prefillDestinataire.destinataireTelephone;
      this.ship.destinataireAdresse.value = this.prefillDestinataire.destinataireAdresse;
      this.ship.destinatairePays.value = this.prefillDestinataire.destinatairePays;
      this.ship.destinataireCodePostal.value = this.prefillDestinataire.destinataireCodePostal;
      this.ship.destinataireVille.value = this.prefillDestinataire.destinataireVille;
      this.ship.destinataireBatEtage.value = this.prefillDestinataire.destinataireBatEtage;
    },
    prefillDemandeur() {
      this.ship.demandeurSociete.value = this.prefillDemandeur.demandeurSociete;
      this.ship.demandeurNom.value = this.prefillDemandeur.demandeurNom;
      this.ship.demandeurPrenom.value = this.prefillDemandeur.demandeurPrenom;
      this.ship.demandeurEmail.value = this.prefillDemandeur.demandeurEmail;
      this.ship.demandeurTel.value = this.prefillDemandeur.demandeurPhone;
    },
    prefillExpediteur() {
      this.ship.expediteurSociete.value = this.prefillExpediteur.expediteurSociete;
      this.ship.expediteurService.value = this.prefillExpediteur.expediteurService;
      this.ship.expediteurNom.value = this.prefillExpediteur.expediteurNom;
      this.ship.expediteurPrenom.value = this.prefillExpediteur.expediteurPrenom;
      this.ship.expediteurEmail.value = this.prefillExpediteur.expediteurEmail;
      this.ship.expediteurTel.value = this.prefillExpediteur.expediteurTel;
      this.ship.expediteurAdresse.value = this.prefillExpediteur.expediteurAdresse;
      this.ship.expediteurPays.value = this.prefillExpediteur.expediteurPays;
      this.ship.expediteurCodePostal.value = this.prefillExpediteur.expediteurCodePostal;
      this.ship.expediteurVille.value = this.prefillExpediteur.expediteurVille;
      this.ship.expediteurBatEtage.value = this.prefillExpediteur.expediteurBatEtage;
    },
    selectedContact() {

      if (this.step === 1) {
        this.ship.demandeurEmail.value = this.selectedContact.email;
        this.ship.demandeurPrenom.value = this.selectedContact.prenom;
        this.ship.demandeurNom.value = this.selectedContact.name;
        this.ship.demandeurTel.value = this.selectedContact.tel;
        this.ship.demandeurSociete.value = this.selectedContact.company;

        if (this.selectedContact.demandeurNom) {
          this.ship.demandeurSociete.value = this.selectedContact.demandeurSociete;
          this.ship.demandeurNom.value = this.selectedContact.demandeurNom;
          this.ship.demandeurPrenom.value = this.selectedContact.demandeurPrenom;
          this.ship.demandeurEmail.value = this.selectedContact.demandeurEmail;
          this.ship.demandeurTel.value = this.selectedContact.demandeurPhone;
        }
      }

      if (this.step === 2) {
        this.ship.expediteurEmail.value = this.selectedContact.email;
        this.ship.expediteurPrenom.value = this.selectedContact.prenom;
        this.ship.expediteurNom.value = this.selectedContact.name;
        this.ship.expediteurTel.value = this.selectedContact.tel;
        this.ship.expediteurAdresse.value = this.selectedContact.adresse;
        this.ship.expediteurCodePostal.value = this.selectedContact.cp;
        this.ship.expediteurVille.value = this.selectedContact.ville;
        this.ship.expediteurPays.value = this.selectedContact.pays;
        this.ship.expediteurSociete.value = this.selectedContact.company;
      }

      if (this.step === 3) {
        this.ship.destinataireEmail.value = this.selectedContact.email;
        this.ship.destinatairePrenom.value = this.selectedContact.prenom;
        this.ship.destinataireNom.value = this.selectedContact.name;
        this.ship.destinataireTel.value = this.selectedContact.tel;
        this.ship.destinataireAdresse.value = this.selectedContact.adresse;
        this.ship.destinataireCodePostal.value = this.selectedContact.cp;
        this.ship.destinataireVille.value = this.selectedContact.ville;
        this.ship.destinatairePays.value = this.selectedContact.pays;
        this.ship.destinataireSociete.value = this.selectedContact.company;
      }
    },
    step() {
      this.selectedContact = null;
    }
  }
}
</script>

<style scoped lang="scss">
.kg {
  position: relative;

  span {
    position: absolute;
    right: 20px;
    color: #000;
    line-height: 46px;
    display: inline-block;
    height: 46px;
  }
}

textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.vdp-datepicker *, .vdp-datepicker__calendar {
  color: #333 !important;

  &.disabled {
    color: #ddd !important;
  }
}

.vdp-datepicker__calendar .cell {
  color: #333 !important;

  &.disabled {
    color: #ddd !important;
  }
}

.vdp-datepicker__calendar .cell.disabled {
  color: #ddd !important;
}

.vdp-datepicker__calendar .disabled {
  color: #ddd;
}

.vdp-datepicker__calendar .cell.day-header {
  color: #333 !important;
}

.prefill {
  button {
    line-height: 37px;
    margin-bottom: 50px;
  }
}

.right {
  text-align: right;
}


.form-group {
  position: relative;
}

input[type=time] {
  color: #FFF;

  &:invalid {
    &:before {
      color: grey;
      content: attr(placeholder);
      padding-left: 10px;
      pointer-events: none;
      position: absolute;
      left: 10px;
    }
  }

  &:active {
    &:before {
      display: none;
    }
  }

  &:focus, &:valid, &:active {
    color: #000;

    &:before {
      display: none;
    }
  }
}

/*
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    transform: translateX(10px);
    opacity: 0;
}*/

.label-file {
  background: #FFF;
  display: block;
  line-height: 46px;
  padding-left: 19px;
  color: #888;
  font-size: 15px;
  margin-bottom: 30px;
}

// et on masque le input
#files {
  display: none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  display: block;
  margin-top: 5px;

  &.final {
    width: 66px;
    height: 66px;
    margin-right: 0;
  }
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.form-grid2 {
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;
    align-items: center;

    label.radio {
      width: 160px;
    }
  }
}

>>> {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #000;
  --vs-dropdown-option-color: #000;

  --vs-search-input-color: #000;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #000;
}
</style>
