<template>
    <div>
        <l-map :zoom=12 :center="[trackerInfos.Latitude, trackerInfos.Longitude]" v-if="trackerInfos">
            <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <l-marker :lat-lng="getLatLng(trackerInfos.Latitude, trackerInfos.Longitude)">
                <l-icon
                    icon-url="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
                    :icon-size="[25, 41]"
                    :icon-anchor="[12, 42]"
                    :popup-anchor="[0, -42]"
                />
                <l-popup>
                    <div>
                        <div>
                            <strong>Température : {{ trackerInfos.DeviceTemp }}°C</strong>
                        </div>
                    </div>
                </l-popup>
            </l-marker>
        </l-map>
    </div>
</template>

<script>
    import L from 'leaflet'
    import moment from 'moment'
    import { latLng } from "leaflet";
    import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet";

    export default {
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LTooltip,
            LIcon
        },
        props: {
            shipCode: null
        },
        data() {
            return {
                trackerInfos: null,
            }
        },
        methods: {
            getLatLng(lat, lng) {
                return latLng(lat, lng);
            }
        },
        mounted() {
            axios.get(`/tracker-position/${this.shipCode}`).then(response => {
                console.log(response.data.TripStatusList);
                response.data.TripStatusList.forEach(tracker => {
                    this.trackerInfos = tracker;
                })
            })
        },
        computed: {
            formatedDate() {
                return moment(this.trackerInfos.DateTimeAcquiredUTC).format('DD/MM/YYYY HH:mm');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .vue2leaflet-map {
        position: fixed;
        outline: none;
        z-index: 0;
        left: 0;
        width: calc(100% - 961px);
        top: 0;
        bottom: 0;
        height: 100vh;
    }
</style>